
import { Component, Vue } from 'vue-property-decorator'

@Component({
  head(this: Index): object {
    return {
      title: this.$t('pageTitleIndex'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('metaContentIndex')
        }
      ]
    }
  },
  layout: 'landing-page',
  auth: 'guest'
})
export default class Index extends Vue {
  faqContent: any = {};

  mounted() {
    this.faqContent = {
      title: this.$t('faqTitle'),
      subTitle: this.$t('faqSubText'),
      questions: this.$t('faqIndex')
    }
  }
}
