var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("landing-overview-section"),
      _vm._v(" "),
      _c("landing-features-section"),
      _vm._v(" "),
      _c("landing-committed-section"),
      _vm._v(" "),
      _c("landing-star-testimonial-section"),
      _vm._v(" "),
      _c("landing-smm-panels-section"),
      _vm._v(" "),
      _c("landing-services-section"),
      _vm._v(" "),
      _c("landing-ig-flatrate-section"),
      _vm._v(" "),
      _c("landing-followers-compute"),
      _vm._v(" "),
      _c("landing-process-steps"),
      _vm._v(" "),
      _c("landing-testimonial-section"),
      _vm._v(" "),
      _c("landing-blackhat-forum-section"),
      _vm._v(" "),
      _c("landing-faq-section", { attrs: { content: _vm.faqContent } }),
      _vm._v(" "),
      _c("landing-boost-section"),
      _vm._v(" "),
      _c("landing-send-us-message-section"),
      _vm._v(" "),
      _c("landing-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }